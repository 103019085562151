import React, { Component } from 'react'
import NewClientInput from './NewClientInput'
import Login from './Auth/Login'
import Auth from './Auth/Auth'

import './App.css'


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // cards: this.generateCards(),
      currentPair: [],
      guesses: 0,
      matchedCardIndices: [],
      hallOfFame: null,
    }
  }

  async handleClick() {
    console.log('clicked !')
  }

  sendToBack() {
    console.log('send to back !')    
  }


  render() {
    // const {cards, guesses, matchedCardIndices, hallOfFame} = this.state
    // const won = matchedCardIndices.length === 2//cards.length
    // const won = new Date().getSeconds() % 2 === 0

    return (
      <div className="automation">
        <Auth/>
        {/* <Login/>   */}
        {/* <NewClientInput/> */}
      </div>
    )
  }
}

export default App