import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './NewClientInput.css'

class NewClientInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientName: '',
            birthDay: '',
            birthPlace: '',
            adress: '',
            postalCode: '',
            cityAdress: '',
            coachingBeginDate: '',
            coachingEndDate: '',
            coachingPrice: 0,
            payementNumber: 0,
            contractDate: this.getCurrentDate(),
            contractCity: '',
            emailAdress: '',
        }
    }

    handleInputChange = event => {
        const name = event.target.name
        
        console.log(event.target.value)
            this.setState({
                [name]: event.target.value
            })
    }

    getCurrentDate() {
        let today = new Date()
        let day = today.getDate()
        let month = today.getMonth()
        let currentDate = today.getFullYear() + '-' + (month < 10 ? '0' + (month + 1) : (month + 1)) + '-' + (day < 10 ? '0' + day : day)
        return (currentDate)
    }

    storedForm = event => {
        alert('envoie to port 3000')
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ Client: {
                clientName: this.state.clientName,
                birthDay: this.state.birthDay,
                birthPlace: this.state.birthPlace,
                adress: this.state.adress + ' ' + this.state.postalCode + ' ' + this.state.cityAdress,
                coachingBeginDate: this.state.coachingBeginDate,
                coachingEndDate: this.state.coachingEndDate,
                coachingPrice: this.state.coachingPrice,
                payementNumber: this.state.payementNumber,
                contractDate: this.state.contractDate,
                contractCity: this.state.contractCity,
                emailAdress: this.state.emailAdress,
               }
            })
        };
        fetch('http://localhost:3000/api/client', requestOptions)
        .then(response => response.json())
        .then(response => { alert(response) })
        // .catch(error => { alert(error) });
        // fetch("http://localhost:3000/api/client", {
        //     "method": "POST",
        //     // "headers": {
        //     //     "x-rapidapi-host": "fairestdb.p.rapidapi.com",
        //     //     "x-rapidapi-key": "apikey",
        //     //     "content-type": "application/json",
        //     //     "accept": "application/json"
        //     //     },
        //     // "body": JSON.stringify({
        //     //     clientName: this.clientName,
        //     //     birthDay: this.birthDay,
        //     //     birthPlace: this.birthPlace,
        //     //     adress: this.adress,
        //     //     coachingBeginDate: this.coachingBeginDaten,
        //     //     coachingEndDate: this.coachingEndDaten,
        //     //     coachingPrice: this.coachingPricen,
        //     //     payementNumber: this.payementNumbern,
        //     //     contractDate: this.contractDaten,
        //     //     contractCity: this.contractCityn,
        //     //     emailAdress: this.emailAdressn,
        //     //     })
        //     //     ,
        //         "body": ({test : "test"}),
        //     })
        //     .then(response => response.json())
        //     .then(response => {
        //     alert(response)
        //     })
        //     .catch(err => {
        //     alert(err);
        //     });
    }

    render() {
        const payementNumberPossible = [];
        for (let i = 0; i < 7; i++) {
            payementNumberPossible[i] = i;
        }

        return (
        <div>
        <h1>Enregistrement d'un nouveau client</h1>

        <div className="wrapper">
        <form className="newClientFormulaire" onSubmit={this.storedForm}>
            <label>
                Nom prénom et bite : {'\n'}
                <input className="champs" name="clientName" type="text" value={this.state.clientName} onChange={this.handleInputChange} required/> {'\n'}
            </label>
            <label>
                Date de naissance : {'\n'}
                <input className="champs" name="birthDay" type="date" value={this.state.birthDay} onChange={this.handleInputChange} required/> {'\n'}
            </label>
            <label>
                Lieu de naissance : {'\n'}
                <input className="champs" name="birthPlace" type="text" value={this.state.birthPlace} onChange={this.handleInputChange} required/> {'\n'}
            </label>
            <label>
                Adresse : {'\n'}
                <input className="champs" name="adress" type="text" value={this.state.adress} onChange={this.handleInputChange} required/> {'\n'}
            </label>            <label>
                Code postale : {'\n'}
                <input className="champs" name="postalCode" type="text" value={this.state.postalCode} onChange={this.handleInputChange} required/> {'\n'}
            </label>            <label>
                Ville : {'\n'}
                <input className="champs" name="cityAdress" type="text" value={this.state.cityAdress} onChange={this.handleInputChange} required/> {'\n'}
            </label>
            <label>
                Date de coaching prévisionnelle début : {'\n'}
                <input className="champs" name="coachingBeginDate" type="date" value={this.state.coachingBeginDate} onChange={this.handleInputChange} required/> {'\n'}
            </label>
            <label>
                Date de coaching prévisionnelle fin : {'\n'}
                <input className="champs" name="coachingEndDate" type="date" value={this.state.coachingEndDate} onChange={this.handleInputChange} required/> {'\n'}
            </label>
            <label>
                Prix du coaching TTC : {'\n'} 
                <input className="champs" name="coachingPrice" type="number" value={this.state.coachingPrice} onChange={this.handleInputChange} required/> {'\n'}
            </label>
            <label>
                Nombre de versement (entre 1 et 6) : {'\n'}
                <select className="champs" name="payementNumber" onChange={this.handleInputChange} required>
                {payementNumberPossible.map((number, index) => (
                    <option value={number}>{index}</option>
                ))}
                  </select> {'\n'}
            </label>
            <label>
                Contrat édité dans la ville de : {'\n'}
                <input className="champs" name="contractCity" type="text" value={this.state.contractCity} onChange={this.handleInputChange} required/> {'\n'}
            </label>
            <label>
                Adresse email du client : {'\n'}
                <input className="champs" name="emailAdress" type="mail" value={this.state.emailAdress} onChange={this.handleInputChange} required/> {'\n'}
            </label>
            <button type="submit" className="button">Save</button>
        </form>
        </div>
        </div>
        )
    }
}

export default NewClientInput