import React, { Component } from 'react';
import './Login.css';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
        }
    }
    
    handleChange = e => {
        this.setState({
         [e.target.name]:e.target.value
       })}



       render() {
        return (
        <div className="container">
            <div className="title"> Page de connexion </div>
                <form className="loginForm">
                    <label className="blockLogin">
                        <label className="fields">
                        <input className="field"
                            type="email"
                            name="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                            data-message-required="Please enter your email address"
                            data-message-email="Please enter a VALID email address"
                            placeholder="Nom d'utilisateur"
                            required
                            />
                        </label>
                        <label className="fields">
                        <input className="field"
                            type="password"
                            name="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                            data-minlength="3"
                            data-maxnlength="20"
                            data-message="Please enter your password"
                            placeholder="Mot de passe"
                            required
                            />
                        </label>
                    </label>
                <button type="submit" className="button" >Connexion</button>
            </form>
            </div>
        )
    }

}

export default Login