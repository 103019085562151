import React, { Component } from 'react';
import Login from './Login'
import './Auth.css'

class Auth extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div className="container">
            <div className="Autho">
                <Login/>
            </div>
            </div>
            )
    }

}

export default Auth